import { useContext, useEffect, useState, useMemo, useCallback } from 'react';

import { AppContext } from '../../common/constants';
import { TerminalService } from '../../common/services';
import { LoadableContent, NoResults, Loader } from '../../components';

import { useNavigate } from 'react-router-dom';

import Green02Image from  '../../layout/themes/images/icon/green_set_ph-02.png';
import Terminal01Image from  '../../layout/themes/images/icon/terminal-01.png';
import Vue02Image from  '../../layout/themes/images/icon/vue-02-02.png';
import RefreshImage from  '../../layout/themes/images/icon/refresh.png';
import { NumberHelper, DateHelper } from '../../common/helper';


const Termminal = () => {
    
  const {store} = useContext(AppContext);
  const [terminaux, setTerminaux] = useState([]);
  const [soldes, setSoldes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [soldesLoading, setSoldesLoading] = useState(false)
  const [forceUpdateSolde, setForceUpdateSolde] = useState(0)

  
  const date = useMemo(() => DateHelper.getMonthFirstAndLastDate(`${store.month}-01`), [store.month])

  const navigate = useNavigate();

    useEffect(() => {
        if(store?.concessionnaire?.id) {
          setLoading(true)
          TerminalService.get(store.concessionnaire.id, {debut : date.firstDay?.toISOString(), fin : date.lastDay?.toISOString()})
          .then(setTerminaux)
          .finally(() => setLoading(false))
        }
      }, [store?.concessionnaire?.id, date])


      useEffect(() => {
        if(store?.concessionnaire?.id) {
          setSoldesLoading(true)
          TerminalService.getSoldes(store.concessionnaire.id)
          .then(setSoldes)
          .finally(() => setSoldesLoading(false))
        }
    }, [store?.concessionnaire?.id, forceUpdateSolde])

    /*const displaySolde = useCallback((terminal) => {
      const updatedSolde = soldes?.find(solde => solde.numero === terminal.numero)
     if(updatedSolde?.montant) return NumberHelper.formatWithThousandsSeparator(updatedSolde.montant);
     const montantSolde =  isNaN(terminal?.solde) ? terminal?.solde?.montant :  terminal?.solde;
     return (<div><div>{NumberHelper.formatWithThousandsSeparator(montantSolde ?? 0)}</div> <div style={{fontSize : "12px"}}> {`${terminal?.solde?.date ? '(au ' + DateHelper.format(terminal?.solde?.date)  + ')': ''}`}</div></div>)
    }, [soldes]) */

    const updatedSolde = useCallback((terminal) => {
      const updatedSolde = soldes?.find(solde => solde.numero === terminal.numero)
     if(updatedSolde?.montant) return NumberHelper.formatWithThousandsSeparator(updatedSolde.montant);
     return (<div style={{fontSize : "12px !important"}}>0</div>)
    }, [soldes]) 

    const seuilAlerte = useCallback((terminal) => {
      const updatedSolde = soldes?.find(solde => solde.numero === terminal.numero)
      return updatedSolde <= terminal?.solde?.seuil 
    }, [soldes]) 

    return (
   <LoadableContent {...{isLoading}}>

<div className="container-fluid theme-hover-bg-zoom-in animatedParent full-height theme-maincolorback-light thin-border-top sub-section" id="particuliers" style={{backgroundSize: 'contain'}} data-pgc-define="SELECT_TERMINAL" data-pgc-define-name="SELECT_TERMINAL">
  <div className="appsv3-admin-bloc animated fadeIn col-xs-12 col-sm-12 col-md-12 col-lg-12 sub-section transactions_list">
    <div className="appsv3-admin-bloc-title appsv3-admin-bloc-title_2">
      {/* <button
    type="button"
    className="btn dropdown-toggle margin-top0 margin-bottom0 pull-right no-border full-height open_filter"
  >
    <img src="images/icon/filter-02.png" width="40px" className="invert" />
  </button> */}
      {/* <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left"> </p> */}
      <p data-pgc-field="appsv3-admin-bloc-title" className="pull-left"> 
      {`Liste des terminaux pour ${store?.concessionnaire?.code}`}
      </p>
    </div>
    <div className="container-fluid no-padding-section">
      <div className="col-xs-12 sub-section col-sm-2 no-padding-section dc-desc col-lg-3 col-md-3">
        <div className="container-fluid no-padding-section full-height">
          <div className="col-md-12 col-lg-12 no-padding-section full-height theme-vertical-align col-xs-4 col-sm-4 desc-pict">
            <div className="container-fluid">
              <p><img src={Green02Image} className="preview_icon" alt="Member Image" style={{marginLeft: 'auto', marginRight: 'auto', width: '80% !important'}} width="100%!important" /> </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 col-xs-8 col-sm-8 no-padding-section full-height theme-vertical-align desc-desc grayscale">
            <div>
              <h4>Selectionner un terminal</h4>
              <p>Veuillez choisir un terminal afin d’afficher plus de détail </p>
            </div>
          </div>
          {/* <div className="col-md-12 col-lg-12 col-xs-3 col-sm-3 full-height theme-vertical-align">
            <div className="container-fluid">
              <h3 className="margin-bottom0 margin-top0 text-center bold theme-text-bold" style={{fontWeight: 'bold', fontSize: '2em'}}>172067</h3>
            </div>
          </div> */}
        </div>
      </div>
      <div className="col-xs-12 col-lg-9 col-md-9 col-sm-12 no-padding-section">
        { terminaux.length ?  terminaux?.map(terminal => <div key={terminal?.id} className="container-fluid terminal-list" data-pgc-define="terminal_list" data-pgc-define-name="terminal_list">
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-1 col-md-1 col-xs-3 col-sm-3" style={{paddingLeft: '0px', backgroundColor: '#efce70', cursor : 'pointer'}} onClick={() => navigate(`terminal/${terminal.id}`, {state : terminal})}>
            <div className="full-height theme-vertical-align bloc" style={{flexDirection : 'column'}} >
              <img src={Terminal01Image} className="preview_icon invert visible-md visible-lg" alt="Member Image" />
              <img src={Terminal01Image} className="preview_icon invert visible-xs visible-sm" style={{width : '30px', height : '30px'}} alt="Member Image" />
              <div className="bloc">
                <p className="bold margin-bottom0 margin-top0 label visible-xs visible-sm">TERMINAL</p>
                <p className="bold margin-bottom0 margin-top0 data theme-text-bold name visible-xs visible-sm">{terminal?.numero ?? 0}</p>
              </div>
            </div>
          </div>
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-3 col-md-3 col-sm-4 col-xs-4 hidden-xs hidden-sm" style={{paddingLeft: '0px', backgroundColor: '#efce70'}} data-pgc-edit="aaaaa[class, style, no_content]">
            <div className="bloc">
              <p className="bold margin-bottom0 margin-top0 label">TERMINAL</p>
              <p className="bold margin-bottom0 margin-top0 data theme-text-bold name">{terminal?.numero ?? 0}</p>
              <span className="description hidden" />
            </div>
          </div>
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-3 col-md-3 bloc thin-border-right col-sm-4 col-xs-4" style={{paddingLeft: '0px', backgroundColor: !soldesLoading && seuilAlerte ? '#f3531e' : '#fbf1d2'}} >
            <div className="bloc">
              <p className="bold margin-bottom0 label">SOLDE <span style={{fontSize:8}}>(FCFA)</span></p>
              <div className="bold margin-bottom0 margin-top0 data-big" style={{display : 'flex', alignItems: 'center'}}>
                {updatedSolde(terminal)} {soldesLoading && (<Loader style={{width : '15px', height : '15px', marginLeft : '10px'}} />)}
              </div>
              <a onClick={(event) =>{ 
                  event.stopPropagation();
                  setForceUpdateSolde(forceUpdateSolde + 1)
                  }} style={{color : '#337ab7', fontSize : "12px", textDecoration : 'underline',  cursor: 'pointer'}}>Actualiser</a>
              <span className="description hidden" />
            </div>
          </div>
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-3 col-md-3 thin-border-right hidden-sm col-sm-4 col-xs-4" style={{paddingLeft: '0px'}} >
            <div className="bloc">
              <p className="bold margin-bottom0 margin-top0 label">SOLDE VEILLE <span style={{fontSize:8}}>(FCFA)</span></p>
              <p className="bold margin-bottom0 margin-top0 data-big">
                {<div><div>{NumberHelper.formatWithThousandsSeparator(terminal?.solde?.montant ?? 0)}</div> <div style={{fontSize : "12px"}}> {`${terminal?.solde?.date ?  DateHelper.format(terminal?.solde?.date, true) : ''}`}</div></div>}
              </p>
              <span className="description hidden" />
            </div>
          </div>
          <div className="product-name-desc pull-left text-left no-padding-section full-height theme-vertical-align col-lg-2 col-md-2 col-sm-1 col-xs-1" style={{paddingLeft: '0px'}} >
            <div className="bloc text-center">

            <button onClick={() => navigate(`terminal/${terminal.id}`, {state : terminal})} type="button" className="btn btn-default">
              <img src={Vue02Image}   className="lazy invert load_details" />
            </button>  
            </div>
          </div>
        </div>
        ) : <NoResults className='myLonaci-ro-results'  message="vous n'avez pas de terminaux prépayés" />}
      </div>
    </div>
  </div>
</div>
   </LoadableContent>
   
    )
}


export default Termminal;
import { Divider, Space, Button as AntButton, Table, Typography, Form, Alert, Result } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "../../../components";
import OtpField from "../../../components/forms/fields/OtpField";
import Countdown from "react-countdown";
import _ from "lodash";
import otpService from "../../../common/services/otp.service";
import { ConcessionnaireService, OtpService } from "../../../common/services";
import PatternFormatNumberField from "../../../components/forms/fields/PatternFormatNumberField";
const { Title } = Typography;

const Completionist = () => <span>You are good to go!</span>;

const Step3=({goToPrevious, step, inputValues})=> {
    const [form] = Form.useForm();
    const [isConfirmSubscription, setIsConfirmSubscription] = useState(false);
    const [alertErrors, setAlertErrors] = useState(null);
    const [confirmed, setConfirmed] = useState(false)
    const [otpValues, setOtpValues] = useState()
    const [uuid, setUuid] = useState("12345")
    const [countDowning, setCountDowning] = useState(true)

    const [state, setState]= useState({
        isResendingOTP: false,
        otpStatus: false
    })
    useEffect(()=> {

    }, [step])

    useEffect(()=> {
        if(state?.otpStatus) {
            
            const registerRequest= {
                nom: inputValues?.step2.nom,
                prenom: inputValues?.step2.prenom,
                mobile: inputValues?.step2.phoneNumber,
                email: inputValues?.step2.email, 
                concessionnaires: (inputValues?.step1 || []).map((step)=> step?.concessionnaireId)
            }

            ConcessionnaireService.register(registerRequest)
            .then((response)=> {
                setConfirmed(response?.status)
            })
            .catch(error=> {
                console.log(error)
                setAlertErrors("Une erreur est survenue pendant le traitement de votre requête, veuillez réessayer")
            })
            .finally(setIsConfirmSubscription(false))
        }
    }, [state?.otpStatus])

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          setCountDowning(false);
          return null;
        } else {
          // Render a countdown
          return <span>{minutes}:{seconds}</span>;
        }
      };

    
    const onSubmit=()=> {
        console.log("inputValues :", inputValues)
        setAlertErrors(null)

        //Verification de l'OTP
        form.validateFields().then((value)=> {
            console.log("Values : ", value)
            setIsConfirmSubscription(true)

            OtpService.verify(inputValues?.step2?.phoneNumber, value?.otp)
            .then((response)=> {
                console.log("response :", response?.status)
                if(response?.status) {
                    // Send data to save
                    const registerRequest= {
                        nom: inputValues?.step2.nom,
                        prenom: inputValues?.step2.prenom,
                        mobile: inputValues?.step2.phoneNumber,
                        email: inputValues?.step2.email, 
                        concessionnaires: (inputValues?.step1 || []).map((step)=> step?.concessionnaireId)
                    }
        
                    ConcessionnaireService.register(registerRequest)
                    .then((response)=> {
                        setUuid(response?.username)
                        setConfirmed(true)
                    })
                    .catch(error=> {
                        console.log(error)
                        setAlertErrors(error?.message) //"Une erreur est survenue pendant le traitement de votre requête, veuillez réessayer"
                    })
                    .finally(setIsConfirmSubscription(false))
                    
                    
                    //setState(state=> ({...state, otpStatus: true}))
                }
                else {
                    setAlertErrors(response?.message)
                }
            })
            .catch((error)=> {
                console.log("error ::: ", error)
                setAlertErrors(error?.message)
            })
            .finally(setIsConfirmSubscription(false))
        })
        .catch(error=> console.log(error))
        //.finally()
    }

    const onChangeOtp=(value)=> {
        const v = _.replace(value, new RegExp("[\\s-_]","g"), "");
        setOtpValues(v)
    }

    const resentOTP=()=> {
        setCountDowning(true)
        setState(state=> ({...state, isResendingOTP: true}));

        OtpService.generate(inputValues?.step2?.phoneNumber)
            .then((response)=> {
                if(response?.status) {
                    setState(state=> ({...state, isResendingOTP: false}));
                }
                else {
                    setAlertErrors(response?.message)
                }
            })
            .catch(error=> setAlertErrors("Une erreur est survenue, veillez réessayer"))
            .finally(()=> {
                setState(state=> ({...state, isResendingOTP: false}));
            })
    }

    return(
        <React.Fragment>
            {!confirmed ? (
                <Form form={form}>
                    <div className="container-fluid no-padding-section">
                        <h3>Étape 2 / 2 : Informations sur le distributeur</h3>
                        <p>Renseignez le code de vérification reçu par SMS</p>
                    </div>
                    <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent " style={{backgroundColor: 'transparent !important'}}>
                        <div id="loaded" className="container-fluid" />
                        <div className="container-fluid no-padding-section">
                            
                            <Space direction='vertical' style={{width: "100%"}}>
                                {/* <PatternFormatNumberField  name={"otp"} onChange={onChangeOtp} disable={isConfirmSubscription} placeholder="Code de vérification (reçu par sms)" format="#-#-#-#-#-#" className="form-control" required={true}/> */}
                                <OtpField length={6} required={true} name={"otp"} onChange={onChangeOtp} disable={isConfirmSubscription}/>
                                <Title level={5} >Vous n'avez pas reçu de code ?</Title>
                                <AntButton loading={state?.isResendingOTP} type="link" onClick={()=> resentOTP()} block disabled={isConfirmSubscription} ><span className="resent-otp">Renvoyer le code</span></AntButton>
                                
                                {countDowning && <Countdown
                                    key={Date.now().toString()}
                                    date={Date.now() + 60000}
                                    renderer={renderer}
                                    autoStart= {step === 2 && countDowning}
                                />}
                                {alertErrors && (<div className="animated"><Alert message={`${alertErrors}`} type="error" showIcon/></div>)}
                            </Space>
                            <div style={{display : 'flex', justifyContent : 'center'}} className="virgin-button text-right sub-section" data-pgc="nostyle_button" data-pgc-field="nostyle_buttonr">
                                <button type="button" onClick={() => goToPrevious()}  className="btn white thin-border btn-default virgin-button" style={{marginRight : '5px'}} >Precedent</button>
                                <Button type="button" onClick={onSubmit} disabled={_.size(otpValues) !== 6} isLoading={isConfirmSubscription} className={`btn white thin-border btn-default btn-primary virgin-button launch_transaction `} >
                                Valider
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            ) : (
                <div >
                    <div className="container-fluid no-padding-section">
                        <h3>Validée</h3>
                    </div>
                    <div className="appsv3-admin-bloc animated fadeIn no-padding-section container-fluid bg-transparent " style={{backgroundColor: 'transparent !important'}}>
                        <div id="loaded" className="container-fluid" />
                        <div >
                            
                            <Result
                                style={{color: 'red'}}
                                status="success"
                                title="Bienvenue sur MyLonaci"
                                subTitle={<>{`Votre identifiant unique (UID) est`} <strong>{uuid}</strong>. {`Pour accéder à votre compte, utiliser cet UID et le mot de passe temporaire reçu par sms. Vous serez invité à changer de mot de passe après connexion.`}</>}
                                extra={<div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Button type="button" onClick={()=> window.location.reload()} className={`btn white thin-border btn-default btn-primary virgin-button launch_transaction add-btn`} >
                                        Acceder à mon compte
                                    </Button></div>}
                            />
                        </div>
                    </div>
                </div>
            )}
            
        </React.Fragment>
    )
}

export default Step3;